<template>
  <f7-page :page-content="false">
    <app-navigation title="Print article labels" />
    <f7-page-content v-if="!activeItem?.labelPrinted">
      <app-breadcrumbs :breadcrumbs="breadcrumbs" />
      <entry-detail-with-detail-popup
        v-if="activeItem?.todoItem"
        :quantity="activeItem.quantityEntered ?? 0"
        :article="activeItem.todoItem.article"
      />
      <print-article-labels-card
        v-if="activeItem?.todoItem"
        :article="activeItem.todoItem.article"
        :print-amount="1"
        :goods-receipt-line-id="activeItem.todoItem.goodsReceiptLineId"
        :line-amount="activeItem?.quantityEntered"
        @documents-printed="onDocumentsPrinted"
      />
    </f7-page-content>
  </f7-page>
</template>
<script setup lang="ts">
import AppNavigation from '@components/AppNavigation.vue'
import PrintArticleLabelsCard from '@pages/inbound/components/PrintArticleLabelsCard.vue'
import useProjectInboundSort from '@composables/useProjectInboundSort'
import { f7 } from 'framework7-vue'
import EntryDetailWithDetailPopup from '@components/Article/EntryDetailWithDetailPopup.vue'
import AppBreadcrumbs from '@components/AppBreadcrumbs.vue'

const { activeItem, updateProjectItem, createBreadcrumbs } = useProjectInboundSort()

const breadcrumbs = createBreadcrumbs(activeItem.value, {
  href: '#',
  title: 'Print label(s)'
})

const onDocumentsPrinted = async () => {
  activeItem.value.labelPrinted = true

  await updateProjectItem(activeItem.value)

  f7.views.main.router.navigate('/inbound/sort/redirect/')
}
</script>
