import useLoading from '@composables/useLoading'
import useShipment from '@composables/useShipment'
import { IArticle } from '@graphql/article/types'
import {
  IAddPackSourceItemToOutboundLoadCarrierRequest,
  IBulkMarkPrePackedRequest,
  IGetPackableItemsByLoadCarrierIdRequest,
  IGetPackProjectByIdRequest,
  IGetPackingListByContextRequest,
  IGetPackSourceItemRequest,
  IGetRegisterableLoadCarriersRequest,
  IMarkPackedRequest,
  IPackProject,
  IPackableIdentity,
  IPackableItem,
  IPackableItemsResult,
  IPackedLine,
  IPackingList,
  IPackSourceItem,
  IPackStation,
  IPrePackColloRequest,
  IRegisterableLoadCarrier,
  IRegisterLoadCarrierRequest,
  IStartPackRequest,
  PackStationType,
  ProcessEnum,
  ICompletePackRequest,
  IRemoveFromOutboundLoadCarrierRequest
} from '@graphql/pack/types'
import { IColliConnection, IShipmentConnection, IShipmentEdge } from '@graphql/shipment/types'
import { toast } from '@services/toast'
import { f7 } from 'framework7-vue'
import { computed, ref } from 'vue'
import { ITask } from '@graphql/task/types'
import {
  IGetLoadCarrierByIdRequest,
  ILoadCarrier,
  PackingMethod,
  StateEnum,
  Strategy,
  TypeEnum
} from '@graphql/pick/types'
import { soundBoard } from '@services/sound'
import { confirmYesNo } from '@/functions/dialog'
import { perceptibleToast } from '@services/perceptibleToast'
import useSettings from '@composables/useSettings'
import useTask from '@composables/useTask'
import { useStore } from '@store/store'
import { ID } from '@graphql/types'
import { notification } from '@services/notification'
import { TypeName } from '@graphql/search/types'
import { IPrintContext, PrintContext } from '@graphql/document/types'
import useProjectPack from '@composables/useProjectPack'
import { captureException } from '@sentry/vue'
import { IPackProject } from '@store/modules/projects/pack/types'

export default () => {
  const store = useStore()
  const { bulkPrintDocuments, printDocuments: shipmentPrintDocuments } = useShipment()
  const { addProject } = useProjectPack()
  const { withTextPreloaderAndNetworkErrorHandlingAsync } = useLoading()
  const { executeTaskUntilDone } = useTask()
  const { packStation } = useSettings()

  const isPackable = (loadCarrier: ILoadCarrier) => {
    if (isPackablePickLoadCarrier(loadCarrier)) {
      return true
    }

    return isPackableSortLoadCarrier(loadCarrier)
  }

  const isPackablePickLoadCarrier = (loadCarrier: ILoadCarrier) =>
    loadCarrier.packedAt == null &&
    loadCarrier.type === TypeEnum.PICK &&
    [StateEnum.STARTED, StateEnum.PICKED, StateEnum.PACKING].includes(loadCarrier.pickBatch.state)

  const isPackableSortLoadCarrier = (loadCarrier: ILoadCarrier) =>
    loadCarrier.packedAt == null &&
    loadCarrier.type === TypeEnum.OUTBOUND_SORT &&
    [StateEnum.SORTED, StateEnum.PACKING].includes(loadCarrier.pickBatch.state)

  const packedShipments = ref<IShipmentConnection | undefined>(undefined)
  const packedColli = ref<IColliConnection | undefined>(undefined)

  const lastPackedShipment = computed(() => {
    if (packedShipments.value) {
      return packedShipments.value?.edges[packedShipments.value?.edges.length - 1]
    }

    return undefined
  })

  const lastPackedCollo = computed(() => {
    if (packedColli.value) {
      return packedColli.value?.edges[packedColli.value?.edges.length - 1]
    }

    return undefined
  })

  const packedShipmentWithoutLastPacked = computed<IShipmentEdge[]>(() => {
    if (packedShipments.value && lastPackedShipment.value) {
      return packedShipments.value.edges.filter(
        (edge) => edge.node.id !== lastPackedShipment.value?.node.id
      )
    }

    return packedShipments.value.edges
  })

  const packedShipmentsCount = computed(() => {
    if (!packedShipments.value) {
      return 0
    }
    return packedShipments.value.totalCount
  })

  const packedColliCount = computed(() => {
    if (!packedColli.value) {
      return 0
    }
    return packedColli.value.totalCount
  })

  const reprintDocumentFor = async (context: IPrintContext, showErrorAsToast = true) => {
    //TODO: will be replaced with document->reprintDocumentFor
    f7.dialog.confirm(
      `Are you sure you want to reprint the documents for ${context.type.toLowerCase()} #${
        context.identifier
      }`,
      async () => {
        await withTextPreloaderAndNetworkErrorHandlingAsync({
          title: 'Printing documents...',
          maxRetries: 1,
          callback: async () => {
            await printDocumentsFor(context, showErrorAsToast)
          }
        })
      }
    )
  }

  const printDocumentsFor = async (context: IPrintContext, showErrorAsToast = true) => {
    //TODO: will be replaced with document->reprintDocumentFor
    try {
      return await shipmentPrintDocuments({
        context,
        packStationId: (packStation.value as IPackStation).id
      })
    } catch (e: any) {
      if (showErrorAsToast) {
        toast.error(e.message, 0, true).open()
      }

      throw e
    }
  }

  const isMatchingArticle = (
    packableItem: IPackableItem,
    article: IArticle | Partial<IArticle>
  ): boolean => packableItem.packableIdentity.identifier === article.id

  const isQuantityPacked = (item: IPackableItem) =>
    item.quantity === 0 || item.quantity === item.quantityPacked

  const prePackCollo = async (request: IPrePackColloRequest) =>
    store.dispatch('pack/prePackCollo', request)

  const getPackableItemsByLoadCarrierId = async (
    request: IGetPackableItemsByLoadCarrierIdRequest
  ): Promise<Partial<IPackableItemsResult>> =>
    store.dispatch('pack/getPackableItemsByLoadCarrierId', request)

  const getPackingListByContext = async (
    request: IGetPackingListByContextRequest
  ): Promise<Partial<IPackingList>> => store.dispatch('pack/getPackingListByContext', request)

  const markPacked = async (request: IMarkPackedRequest): Promise<boolean> =>
    store.dispatch('pack/markPacked', request)

  const bulkMarkPrePacked = async (request: IBulkMarkPrePackedRequest): Promise<ITask> =>
    store.dispatch('pack/bulkMarkPrePacked', request)

  const getPackReadyItems = async () => store.dispatch('pack/getPackReadyItems')

  const onStartBulkPackProcess = async (loadCarrier: ILoadCarrier, packStation: IPackStation) => {
    await soundBoard.playSuccessSound()

    await confirmYesNo({
      title: `Load carrier ${loadCarrier.id} is auto-packable. Do you want to release it for auto-pack?`,
      yesButtonCallback: async () => {
        await withTextPreloaderAndNetworkErrorHandlingAsync({
          title: `Releasing #${loadCarrier.id} for auto-pack...`,
          timeoutText: `This task may take a while depending on the amount of shipments!`,
          maxRetries: 10,
          autoRetries: 10,
          callback: async () => {
            try {
              const task = await executeTaskUntilDone(
                //@ts-ignore
                async () =>
                  await bulkMarkPrePacked({
                    loadCarrierId: loadCarrier.id,
                    packStationId: packStation.id
                  }),
                `Releasing load carrier "${loadCarrier.id}"`,
                30
              )

              await perceptibleToast.success(
                `Load carrier ${loadCarrier.id} is ready for auto-pack!`
              )

              return task
            } catch (e: any) {
              await perceptibleToast.error(e.message)
              throw e
            }
          }
        })
      }
    })
  }

  const onResult = async (loadCarrier: ILoadCarrier) => {
    if (!loadCarrier.completedAt) {
      await perceptibleToast.error('Load carrier is not (yet) completed!')
      return
    }

    if (!isPackable(loadCarrier)) {
      await perceptibleToast.error(`Load carrier #${loadCarrier.id} is not packable!`)
      return
    }

    if (isSingleSkuPrePacking(loadCarrier, packStation.value as IPackStation)) {
      try {
        await onStartBulkPackProcess(loadCarrier, packStation.value as IPackStation)
      } catch (e: any) {
        await perceptibleToast.error(e.message)
      }

      return
    }

    await onStartPackProcess(loadCarrier)
  }

  const onStartPackProcess = async (loadCarrier: ILoadCarrier) => {
    await soundBoard.playSuccessSound()

    confirmYesNo({
      title: `Are you sure, that you want to start the pack process for load carrier ${loadCarrier.id}?`,
      yesButtonCallback: async () => {
        try {
          const pack = await startPack({
            pickBatchId: loadCarrier.pickBatch.id
          })

          await addProject({
            id: pack.id,
            packProject: pack,
            pickLoadCarriers: [loadCarrier],
            markedReady: false
          } as IPackProject)

          toast.success(`Started pack process for load carrier ${loadCarrier.id}`).open()

          f7.views.main.router.navigate('/pack-entry/')
        } catch (e) {
          captureException(e)

          await perceptibleToast.error(e.message)
        }
      }
    })
  }

  const startPack = async (request: IStartPackRequest): Promise<IPackProject> =>
    store.dispatch('pack/startPack', request)

  const getPackProjectById = async (request: IGetPackProjectByIdRequest): Promise<IPackProject> =>
    store.dispatch('pack/getPackProjectById', request)

  const getPackSourceItem = async (
    request: IGetPackSourceItemRequest
  ): Promise<IPackSourceItem | null> => store.dispatch('pack/getPackSourceItem', request)

  const addPackSourceItemToOutboundLoadCarrier = async (
    request: IAddPackSourceItemToOutboundLoadCarrierRequest
  ): Promise<IPackProject> => store.dispatch('pack/addPackSourceItemToOutboundLoadCarrier', request)

  const removeFromOutboundLoadCarrier = async (
    request: IRemoveFromOutboundLoadCarrierRequest
  ): Promise<boolean> => store.dispatch('pack/removeFromOutboundLoadCarrier', request)

  const getRegisterableLoadCarriers = async (
    request: IGetRegisterableLoadCarriersRequest
  ): Promise<IRegisterableLoadCarrier[]> =>
    store.dispatch('pack/getRegisterableLoadCarriers', request)

  const registerLoadCarrier = async (
    request: IRegisterLoadCarrierRequest
  ): Promise<IRegisterableLoadCarrier> => store.dispatch('pack/registerLoadCarrier', request)

  const completePack = async (request: ICompletePackRequest): Promise<boolean> =>
    store.dispatch('pack/completePack', request)

  const isSingleSkuPrePacking = (loadCarrier: ILoadCarrier, packStation: IPackStation) => {
    const pickBatch = loadCarrier.pickBatch
    if (!pickBatch) {
      return false
    }

    if (loadCarrier.type === TypeEnum.OUTBOUND_SORT) {
      return false
    }

    if (packStation.type !== PackStationType.PRE_PACK) {
      return false
    }

    if (pickBatch.packingMethod !== PackingMethod.AUTOPACK) {
      return false
    }

    return pickBatch.strategy === Strategy.SINGLE_ITEM_ORDERS
  }

  const packableItemIdMatches = (
    packableItem: IPackableItem,
    packableIdentity: IPackableIdentity
  ) => packableItem.packableIdentity.identifier === packableIdentity.identifier

  const transformSearchResult: IPackableIdentity = (result: Partial<IArticle>) => {
    return {
      name: result.name,
      identifier: result.id,
      barcodes: [result.primaryBarcode]
    }
  }

  const packableItemsToPackedLines = (packableItems: IPackableItem[]): IPackedLine[] => {
    const packedLines: IPackedLine[] = []

    for (const packableItem of packableItems) {
      packedLines.push({
        shipmentLineId: packableItem.relationReference?.identifier,
        quantity: packableItem.quantity
      })
    }

    return packedLines
  }

  const getPackingListByReferenceAndLoadCarrierId = async (
    reference: string,
    loadCarrierId: ID
  ) => {
    try {
      return await getPackingListByContext({
        loadCarrierId,
        reference
      })
    } catch (e: any) {
      await perceptibleToast.error(e.message)
    }
  }

  async function startPackingControl(packingList: IPackingList, barcode: string) {
    const packableIdentities = getPackableIdentityByMatchingBarcode(packingList, barcode)

    await notification.info({ message: 'please scan all items for packing!' }).open()
    await f7.views.main.router.navigate('/pack/packing-control/', {
      props: {
        packingList,
        packableIdentity: packableIdentities[0],
        barcode
      }
    })
  }

  const isMatchingBarcode = (aBarcode: string, bBarcode: string) => aBarcode === bBarcode

  const getPackableIdentityByMatchingBarcode = (
    packingList: IPackingList,
    givenBarcode: string
  ) => {
    return packingList.packableItems
      .map((packableItem) => {
        const barcodeMatches = packableItem.packableIdentity.barcodes.filter((barcode) =>
          isMatchingBarcode(barcode, givenBarcode)
        )

        if (barcodeMatches.length > 0) {
          return packableItem.packableIdentity
        }

        return null
      })
      .filter((packableIdentity) => packableIdentity)
  }

  const getMatchingBarcode = (packableItem: IPackableItem, givenBarcode: string) => {
    const barcodeMatches = packableItem.packableIdentity.barcodes.filter((barcode) =>
      isMatchingBarcode(barcode, givenBarcode)
    )

    if (barcodeMatches && barcodeMatches.length > 0) {
      return barcodeMatches[0]
    }

    toast.error(`Scanned barcode "${givenBarcode}" does not match with swiped item!`).open()

    return null
  }

  const printBulkOnPackStation = async (
    packableItem: IPackableItem,
    packStation: IPackStation,
    loadCarrierId: ID
  ) => {
    await bulkPrintDocuments({
      packStationId: packStation.id,
      loadCarrierId,
      barcode: packableItem.packableIdentity.barcodes[0]
    })
  }

  const prePackSortCompartment = async (
    shipmentId: ID,
    barcode: string,
    packingList: IPackingList
  ) => {
    const identifier = await prePackCollo({
      shipmentId,
      packStationId: (packStation.value as IPackStation).id,
      compartmentReference: barcode,
      packedLines: packableItemsToPackedLines(packingList.packableItems)
    })

    await withTextPreloaderAndNetworkErrorHandlingAsync({
      title: 'Printing documents...',
      maxRetries: 1,
      callback: async () => {
        await printDocumentsForPackedList(packingList, new PrintContext(identifier, TypeName.Collo))

        await toast
          .success(`Pre-pack label for compartment ${barcode} has been sent to the printer!`)
          .open()
      }
    })
  }

  const printShipmentDocuments = async (
    identifier: ID,
    barcode: string,
    packingList: IPackingList
  ) => {
    await withTextPreloaderAndNetworkErrorHandlingAsync({
      title: 'Printing documents...',
      maxRetries: 1,
      callback: async () => {
        await printDocumentsForPackedList(
          packingList,
          new PrintContext(identifier, TypeName.Shipment)
        )

        await toast
          .success(`Document(s) of shipment #${barcode} have been sent to the printer(s)!`)
          .open()
      }
    })
  }

  const getLoadCarrierById = (request: IGetLoadCarrierByIdRequest) =>
    store.dispatch('pack/getLoadCarrierById', request)

  const isMatchingIdentity = (a: IPackableItem, b: IPackableItem) =>
    a.packableIdentity.identifier === b.packableIdentity.identifier

  const printDocumentsForPackedList = async (packingList: IPackingList, context: IPrintContext) => {
    try {
      const result = await printDocumentsFor(context, false)

      if (result.data.data.printDocuments.total === 0) {
        await toast.success('No document to print!').open()

        return
      }

      let mustConfirmPrintedDocuments = true
      if (packingList.process === ProcessEnum.SORT_COMPARTMENT_PACK) {
        if (result.data.data.printDocuments.attachments === 0) {
          mustConfirmPrintedDocuments = false
        }
      }

      if (!mustConfirmPrintedDocuments) {
        return
      }

      await confirmYesNo({
        title: 'Are all documents printed?',
        noButtonText: 'No, reprint',
        noButtonCallback: async () => {
          await reprintDocumentFor(context, false)
        }
      })
    } catch (e: any) {
      await perceptibleToast.staticError(e.message)
      throw e
    }
  }

  return {
    reprintDocumentFor,
    printDocumentsFor,
    packedShipments,
    packedColli,
    lastPackedShipment,
    lastPackedCollo,
    packedShipmentWithoutLastPacked,
    packedShipmentsCount,
    packedColliCount,
    isMatchingArticle,
    isQuantityPacked,
    prePackCollo,
    getPackableItemsByLoadCarrierId,
    getPackingListByContext,
    markPacked,
    bulkMarkPrePacked,
    onStartBulkPackProcess,
    onResult,
    packableItemIdMatches,
    transformSearchResult,
    getPackingListByReferenceAndLoadCarrierId,
    startPackingControl,
    isMatchingBarcode,
    getMatchingBarcode,
    printBulkOnPackStation,
    prePackSortCompartment,
    isMatchingIdentity,
    printShipmentDocuments,
    shipmentPrintDocuments,
    getLoadCarrierById,
    getPackReadyItems,
    getPackProjectById,
    getPackSourceItem,
    addPackSourceItemToOutboundLoadCarrier,
    getRegisterableLoadCarriers,
    registerLoadCarrier,
    completePack,
    removeFromOutboundLoadCarrier
  }
}
