import useProjectPick from '@composables/useProjectPick'
import { notification } from '@services/notification'
import { f7 } from 'framework7-vue'
import { Router } from 'framework7/types'
import { IGetPickTaskRequest, IPickBatchPartitionQueue, PartitionState } from '@graphql/pick/types'
import { store } from '@store/store'
import { computed } from 'vue'
import { IOutboundPickProject } from '@store/modules/projects/pick/types'
import { IKardexProject } from '@graphql/kardex/types'

export const redirect = async ({ resolve }: Router.RouteCallbackCtx) => {
  const { isComplete } = useProjectPick()

  f7.preloader.show('#d6d95f')

  //Store(state) method of composables cannot be used here
  const activeProject = computed<IOutboundPickProject | undefined>(
    () => store.getters['projects/pick/activeProject']
  )

  const printedDocuments = computed<boolean>(() => store.getters['projects/pick/printedDocuments'])

  const kardexProject = computed<IKardexProject | undefined>(
    () => store.getters['projects/pick/kardexProject']
  )

  const selectedPickBatchPartition = activeProject.value?.selectedPickBatchPartition

  const selectedQueue = computed<IPickBatchPartitionQueue | null>(
    () => store.getters['projects/pick/selectedQueue']
  )

  const updateProject = async (payload: IOutboundPickProject) =>
    store.dispatch('projects/pick/updateProject', payload)

  const removeProject = async (pickBatchPartitionId: number) =>
    await store.dispatch('projects/pick/removeProject', pickBatchPartitionId)

  const getPickTask = async (request: IGetPickTaskRequest) =>
    await store.dispatch('projects/pick/getPickTask', request)

  let processablePickBatchPartition = null
  try {
    processablePickBatchPartition = await getPickTask({
      partitionQueue: selectedQueue.value
        ? {
            identifier: selectedQueue.value?.identifier.toString(),
            type: selectedQueue.value?.type
          }
        : null
    })
    f7.preloader.hide()
  } catch (e) {
    f7.preloader.hide()
    throw e
  }

  if (
    selectedPickBatchPartition &&
    selectedPickBatchPartition.id !== processablePickBatchPartition?.id
  ) {
    await removeProject(Number(selectedPickBatchPartition.id))

    notification
      .info({
        message: `Pick batch #${selectedPickBatchPartition.pickBatch?.id} was completed elsewhere, automatically closed the task on your device.`,
        closeTime: 5000,
        title: 'Pick project closed'
      })
      .open()
  }

  if (!selectedPickBatchPartition) {
    resolve('/pick/', {
      props: {
        selectedPickBatchPartition: processablePickBatchPartition
      }
    })

    return
  }

  if (selectedPickBatchPartition) {
    activeProject.value!.selectedPickBatchPartition = processablePickBatchPartition

    await updateProject(activeProject.value)

    if (isComplete(selectedPickBatchPartition)) {
      return resolve('/pick/confirm-picked/', {
        props: {
          pickBatchPartition: selectedPickBatchPartition
        }
      })
    }

    if (selectedPickBatchPartition.state === PartitionState.STARTED) {
      if (selectedPickBatchPartition.bulkPrintingLabelsAllowed && !printedDocuments.value) {
        return resolve(`/pick/${selectedPickBatchPartition.id}/print-labels/`)
      }

      if (kardexProject.value) {
        if (kardexProject.value.kardexTask.state !== 'finished') {
          return resolve('/pick/', {
            props: {
              selectedPickBatchPartition: selectedPickBatchPartition
            }
          })
        }

        return resolve(`/kardex/${kardexProject.value.id}/`)
      }

      return resolve(`/pick/line/${selectedPickBatchPartition.id}/`, {
        props: {
          pickBatchPartition: selectedPickBatchPartition
        }
      })
    }
  }

  resolve('/pick/', {
    props: {
      selectedPickBatchPartition: selectedPickBatchPartition
    }
  })
}
