<template>
  <f7-block-title class="margin-top" :class="{ 'skeleton-text': isLoading }">
    <string-label v-if="loadCarrier" :value="`Load carrier ${loadCarrier.id}`" />
  </f7-block-title>
  <f7-card :class="{ 'skeleton-text': isLoading }">
    <f7-card-content>
      <f7-list media-list>
        <f7-list-item header="Sequence">
          <string-label :value="getSequenceFor(loadCarrier)" />
        </f7-list-item>
        <f7-list-item header="Reference">
          <string-label :value="loadCarrier.reference" />
        </f7-list-item>
        <f7-list-item header="Type">
          <string-label :value="loadCarrier.typeForHuman" />
        </f7-list-item>
        <f7-list-item header="Created at">
          <date-time-label :value="loadCarrier.createdAt" />
        </f7-list-item>
        <f7-list-item header="Updated at">
          <date-time-label :value="loadCarrier.createdAt" />
        </f7-list-item>
        <f7-list-item header="Started at">
          <date-time-label :value="loadCarrier?.startedAt" />
        </f7-list-item>
        <f7-list-item header="Started by">
          <string-label :value="loadCarrier.startedBy?.name" />
        </f7-list-item>
        <f7-list-item header="Completed at">
          <date-time-label :value="loadCarrier?.completedAt" />
        </f7-list-item>
        <f7-list-item header="Completed by">
          <string-label :value="loadCarrier.completedBy?.name" />
        </f7-list-item>
        <f7-list-item header="State">
          <string-label :value="loadCarrier?.stateForHuman" />
        </f7-list-item>
        <f7-list-item header="Pick batch #" v-if="loadCarrier?.pickBatch">
          <string-label :value="loadCarrier?.pickBatch?.id" />
        </f7-list-item>
        <f7-list-item header="Location">
          <string-label :value="loadCarrier?.location?.name" />
        </f7-list-item>
      </f7-list>
    </f7-card-content>
  </f7-card>
</template>
<script lang="ts" setup>
import { ILoadCarrier } from '@graphql/pick/types'
import StringLabel from '@components/label/StringLabel.vue'
import DateTimeLabel from '@components/label/DateTime.vue'
import useOutboundSort from '@composables/useOutboundSort'
import { IInboundSortLoadCarrier } from '@graphql/inbound/types'

withDefaults(
  defineProps<{
    loadCarrier: ILoadCarrier | IInboundSortLoadCarrier
    isLoading: boolean
  }>(),
  {
    isLoading: false
  }
)

const { getSequenceFor } = useOutboundSort()
</script>
