<template>
  <div style="margin: 16px 16px 0 16px">
    <f7-breadcrumbs>
      <f7-breadcrumbs-item v-if="firstBreadcrumb">
        <f7-link :href="firstBreadcrumb.href">{{ firstBreadcrumb.title }}</f7-link>
      </f7-breadcrumbs-item>
      <template v-if="collapsedBreadcrumbs.length > 0">
        <f7-breadcrumbs-separator />
        <f7-breadcrumbs-collapsed class="popover-open" data-popover=".breadcrumbs-popover">
          <f7-popover class="breadcrumbs-popover" style="width: 160px">
            <f7-list>
              <f7-list-item
                v-for="collapsedBreadcrumbItem in collapsedBreadcrumbs"
                link
                :title="collapsedBreadcrumbItem.title"
                :key="collapsedBreadcrumbItem.href"
                @click="f7.views.main.router.navigate(collapsedBreadcrumbItem.href)"
                popover-close
              />
            </f7-list>
          </f7-popover>
        </f7-breadcrumbs-collapsed>
      </template>
      <template v-if="lastBreadcrumb">
        <f7-breadcrumbs-separator />
        <f7-breadcrumbs-item active>{{ lastBreadcrumb.title }}</f7-breadcrumbs-item>
      </template>
    </f7-breadcrumbs>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { f7 } from 'framework7-vue'

export interface IBreadcrumb {
  href: string
  title: string
}

const props = withDefaults(
  defineProps<{
    breadcrumbs?: IBreadcrumb[]
    strong: boolean
  }>(),
  {
    breadcrumbs: () => [{ href: '/home', title: 'Home' }],
    strong: false
  }
)

const firstBreadcrumb = computed<IBreadcrumb | null>(() =>
  props.breadcrumbs.length > 0 ? props.breadcrumbs[0] : null
)

const collapsedBreadcrumbs = computed(() => {
  const items = props.breadcrumbs.length > 0 ? [...props.breadcrumbs] : []

  items.splice(0, 1)
  items.splice(items.length - 1, 1)

  return items
})

const lastBreadcrumb = computed<IBreadcrumb | null>(() =>
  props.breadcrumbs.length > 1 ? props.breadcrumbs[props.breadcrumbs.length - 1] : null
)
</script>
