<template>
  <f7-card>
    <f7-card-header> Print amount </f7-card-header>
    <f7-list media-list>
      <f7-list-item>
        <input-number :value="printAmountRef" @change="onPrintAmountChange"></input-number>
      </f7-list-item>
    </f7-list>
  </f7-card>
  <f7-card :no-shadow="true" style="background: transparent">
    <div class="row">
      <div class="col">
        <f7-button
          large
          color="dimass"
          text-color="white"
          @click="onPrintArticleLabel"
          :disabled="printAmountRef <= 0"
          >Print</f7-button
        >
      </div>
      <div class="col">
        <f7-button large outline @click="onContinue">Continue</f7-button>
      </div>
    </div>
  </f7-card>
</template>
<script setup lang="ts">
import InputNumber from '@components/form/InputNumber.vue'
import { ref, watch } from 'vue'
import { toast } from '@services/toast'
import useSettings from '@composables/useSettings'
import useGoodsReceipt from '@composables/useGoodsReceipt'
import { IArticle } from '@graphql/article/types'
import { ID } from '@graphql/types'
import { captureEvent } from '@sentry/vue'

const props = defineProps<{
  article: IArticle
  printAmount: number
  goodsReceiptLineId: ID
  lineAmount: number | null
}>()

const emit = defineEmits<{
  (e: 'documents-printed', value: boolean)
}>()

const { packStation } = useSettings()
const { goodsReceiptLinePrintArticleLabel } = useGoodsReceipt()

const printAmountRef = ref(props.printAmount)

const onPrintArticleLabel = async () => {
  if (printAmountRef.value <= 0) {
    toast.error('Print amount must be greater than 0!').open()

    return
  }

  if (!packStation.value) {
    toast.error('Go to settings to link a pack station').open()

    return
  }

  try {
    await goodsReceiptLinePrintArticleLabel({
      packStationId: packStation.value.id,
      goodsReceiptLineId: props.goodsReceiptLineId,
      printAmount: printAmountRef.value,
      lineAmount: props.lineAmount
    })

    toast.success('Article label sent to pack-station: ' + packStation.value?.description).open()

    await emit('documents-printed', true)
  } catch (e) {
    captureEvent(e)

    let error = e.message

    if (error === 'Internal server Error') {
      error = 'Something went wrong while trying to print the documents!'
    }

    toast.error(error).open()
  }
}

const onPrintAmountChange = (value: number) => (printAmountRef.value = value)

const onContinue = async () => {
  await emit('documents-printed', false)
}

watch(() => props.printAmount, onPrintAmountChange)
</script>
