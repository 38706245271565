<template>
  <f7-page @page:reinit="onInit">
    <app-navigation title="Putaway"></app-navigation>
    <f7-card v-if="!isPutawayToSort">
      <scan-barcode-animation title="Scan item" />
    </f7-card>
    <f7-block-title>{{ putawayItem.description }} contents</f7-block-title>
    <f7-list
      infinite
      :infinite-distance="50"
      :infinite-preloader="isLoading"
      @infinite="onLoadMoreItems"
    >
      <f7-list-item v-for="(item, index) in loadCarrierItems" :key="index">
        <div class="load-carrier-item">
          <f7-chip class="chip-small">{{ item.compartmentReference }}</f7-chip>
          <div class="name-and-quantity">
            <div class="name font-size-18">
              {{ item.article.name }}
            </div>
            <div class="quantity font-size-18">
              <quantity-label
                :quantity="
                  getStockQuantity(item.article, item.quantity - Number(item.quantityProcessed))
                "
              />
            </div>
          </div>
        </div>
      </f7-list-item>
    </f7-list>
    <div class="kardex" v-if="isKardexButtonAvailable">
      <f7-button
        @click="onStartKardexPutaway"
        large
        fill-md
        color="text-secondary"
        :disabled="isLoadingKardex"
      >
        Start kardex putaway
      </f7-button>
    </div>
  </f7-page>
</template>

<script setup lang="ts">
import AppNavigation from '@components/AppNavigation.vue'
import { eventBus, IEvent } from '@/utilities/scanInput'
import useInbound from '@composables/useInbound'
import {
  IInboundSortLoadCarrierItemConnection,
  IInboundPutawayItem,
  IInboundSortLoadCarrier
} from '@graphql/inbound/types'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import useLoading from '@composables/useLoading'
import { ILoadCarrierItem } from '@graphql/pick/types'
import { perceptibleToast } from '@services/perceptibleToast'
import { f7 } from 'framework7-vue'
import QuantityLabel from '@components/QuantityLabel.vue'
import { soundBoard } from '@services/sound'
import { captureEvent, captureException } from '@sentry/vue'
import ScanBarcodeAnimation from '@components/ScanBarcodeAnimation.vue'
import { toast } from '@services/toast'
import useTask from '@composables/useTask'
import { IKardexProject } from '@graphql/kardex/types'
import useAuthentication from '@composables/useAuthentication'
import { confirmYesNo } from '@/functions/dialog'
import { FunctionalType } from '@graphql/warehouse/types'
import useArticle from '@composables/useArticle'

const { withAsyncLoading, isLoading, withTextPreloaderAndNetworkErrorHandlingAsync } = useLoading()
const {
  getInboundSortLoadCarrierItemsByLoadCarrierId,
  getPutawaySource,
  getInboundSortLoadCarrierById,
  startKardexPutaway
} = useInbound()
const { executeTaskUntilDone } = useTask()
const { currentUser } = useAuthentication()
const { getStockQuantity } = useArticle()

const loadCarrierItems = ref<ILoadCarrierItem[]>([])
const loadCarrier = ref<IInboundSortLoadCarrier>()
const loadCarrierItemConnection = ref<IInboundSortLoadCarrierItemConnection>()
const isLoadingKardex = ref<boolean>(false)
const currentKardexProject = ref<IKardexProject>()

const isPutawayToSort = computed<boolean>(
  () => props.putawayItem.warehouseZone.functionalType === FunctionalType.KARDEX_STORAGE.toString()
)
const isKardexButtonAvailable = computed<boolean>(() => {
  if (!isPutawayToSort.value) {
    return false
  }

  if (!loadCarrier.value?.blockedAt) {
    return true
  }

  return !!(
    loadCarrier.value?.blockedAt && loadCarrier.value.blockedBy?.id === currentUser.value.id
  )
})

let unsubscribe: () => void = () => {}

const props = defineProps<{
  putawayItem: IInboundPutawayItem
}>()

onMounted(async () => {
  await onInit()
})

const onInit = async () => {
  if (!isPutawayToSort.value) {
    unsubscribe = eventBus.on('scanInput', async (event: IEvent) => {
      let identifier = String(event.payload)

      if (event.extraData.length > 0) {
        const goodsReceiptLineIdentifier = event.extraData.find((item) => item.startsWith('PUT'))

        if (goodsReceiptLineIdentifier) {
          identifier = goodsReceiptLineIdentifier
        }
      }

      await onItemScan(identifier)
    })
  }
  loadCarrier.value = await getInboundSortLoadCarrierById({
    loadCarrierId: props.putawayItem.identifier
  })
  await loadItems()
}

const loadItems = async () => {
  await withAsyncLoading(async () => {
    loadCarrierItemConnection.value = await getInboundSortLoadCarrierItemsByLoadCarrierId({
      inboundSortLoadCarrierId: props.putawayItem.identifier
    })

    loadCarrierItems.value = loadCarrierItemConnection.value.edges.map((edge) => edge.node)

    if (loadCarrierItems.value.length === 0) {
      f7.views.main.router.back()
    }
  })
}

const onLoadMoreItems = async () => {
  const connection = loadCarrierItemConnection.value
  if (!connection || !connection.pageInfo?.hasNextPage) {
    return
  }

  await withAsyncLoading(async () => {
    loadCarrierItemConnection.value = await getInboundSortLoadCarrierItemsByLoadCarrierId({
      inboundSortLoadCarrierId: props.putawayItem.identifier,
      afterCursor: connection.pageInfo?.endCursor
    })
    loadCarrierItems.value = [
      ...loadCarrierItems.value,
      ...loadCarrierItemConnection.value.edges.map((edge) => edge.node)
    ]
  })
}

const onItemScan = async (itemIdentifier: string) => {
  try {
    const putawaySource = await getPutawaySource({
      loadCarrierId: props.putawayItem.identifier,
      identifier: itemIdentifier
    })

    await soundBoard.playSuccessSound()

    unsubscribe()

    f7.views.main.router.navigate('/inbound/putaway/process/', {
      props: {
        putawaySource: putawaySource
      }
    })
  } catch (e) {
    captureEvent(e)

    await perceptibleToast.error(e.message)
  }
}

const onStartKardexPutaway = async () => {
  confirmYesNo({
    title: 'Are you sure that you want to start kardex putaway for this load carrier?',
    yesButtonCallback: async () => {
      isLoadingKardex.value = true

      const loadCarrierId = props.putawayItem.identifier
      const loadCarrierDescription = props.putawayItem.description

      try {
        await withTextPreloaderAndNetworkErrorHandlingAsync({
          title: `Starting kardex program for ${loadCarrierDescription}`,
          timeoutText: `This task may take a while depending on the amount of items!`,
          maxRetries: 10,
          autoRetries: 10,
          callback: async () => {
            return await executeTaskUntilDone(
              //@ts-ignore
              async () => {
                currentKardexProject.value = await startKardexPutaway({
                  inboundSortLoadCarrierId: loadCarrierId
                })

                return currentKardexProject.value.kardexTask
              },
              `Creating kardex orders for load carrier "${loadCarrierDescription}"`,
              30
            )
          }
        })
      } catch (e) {
        captureException(e)

        toast.error(e.message).open()

        return
      } finally {
        isLoadingKardex.value = false
      }

      await perceptibleToast.success(`Load carrier ${loadCarrierDescription} is ready for putaway!`)

      f7.views.main.router.navigate(`/kardex/${currentKardexProject.value.id}/`)
    }
  })
}

onUnmounted(() => {
  unsubscribe()
})
</script>

<style scoped>
.load-carrier-item {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.name-and-quantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chip-small {
  font-size: 12px;
  width: max-content;
}

.kardex {
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
</style>
