import {
  IAddLoadCarrierRequest,
  IAddLoadCarrierResponse,
  IPickBatch,
  IPickBatchPartitionQueue,
  IPickBatchPartition,
  ISavePickBatchStateRequest,
  ISavePickBatchStateResponse,
  IStartKardexPickRequest,
  IStartPickProcessRequest,
  IGetPickTaskRequest,
  IGetLoadCarrierByIdRequest,
  ILoadCarrier
} from '@graphql/pick/types'
import {
  ICreateNewLoadCarrierRequest,
  IMarkPickedRequest,
  IMarkPickedResult,
  IOutboundPickProject
} from '@store/modules/projects/pick/types'
import { computed } from 'vue'
import { useStore } from '@store/store'
import { ID } from '@graphql/types'
import { f7 } from 'framework7-vue'
import useLoading from '@composables/useLoading'
import { perceptibleNotification } from '@services/perceptibleNotification'
import { perceptibleToast } from '@services/perceptibleToast'

export default function () {
  const store = useStore()
  const { withAsyncLoading, isLoading } = useLoading()

  const isActive = computed<boolean>(() => store.getters['projects/pick/isActive'])

  const itemCount = computed<number>(() => store.getters['projects/pick/itemCount'])

  const items = computed<IPickBatch[]>(() => store.getters['projects/pick/items'])

  const activeProject = computed<IOutboundPickProject>(
    () => store.getters['projects/pick/activeProject']
  )

  const selectedQueue = computed<IPickBatchPartitionQueue | null>(
    () => store.getters['projects/pick/selectedQueue']
  )

  const getPickBatchPartitionQueues = async (): Promise<IPickBatchPartitionQueue[]> =>
    await store.dispatch('projects/pick/getPickBatchPartitionQueues')

  const updateProject = async (payload: IOutboundPickProject | undefined) =>
    store.dispatch('projects/pick/updateProject', payload)

  const startPickProcess = async (request: IStartPickProcessRequest) =>
    await store.dispatch('projects/pick/startPickProject', request)

  const startKardexPick = async (request: IStartKardexPickRequest) =>
    await store.dispatch('projects/pick/startKardexPick', request)

  const updateProcessedLines = async () =>
    await store.dispatch('projects/pick/updateProcessedLines')

  const savePickBatchLineState = async (
    request: ISavePickBatchStateRequest
  ): Promise<ISavePickBatchStateResponse> =>
    await store.dispatch('projects/pick/savePickBatchLineState', request)

  const updateSelectedQueue = async (queue: IPickBatchPartitionQueue) =>
    await store.dispatch('projects/pick/updateSelectedQueue', queue)

  const removeProject = async (pickBatchPartitionId: ID) =>
    await store.dispatch('projects/pick/removeProject', pickBatchPartitionId)

  const addProject = async (payload: IOutboundPickProject): Promise<void> =>
    await store.dispatch('projects/pick/addProject', payload)

  const markPicked = async (request: IMarkPickedRequest): Promise<IMarkPickedResult> =>
    await store.dispatch('projects/pick/markPicked', request)

  const createNewPickLoadCarrier = async (request: ICreateNewLoadCarrierRequest) =>
    await store.dispatch('projects/pick/createNewLoadCarrier', request)
  const getLocationToVisit = async (pickBatchPartitionId: ID) =>
    await store.dispatch('projects/pick/getLocationToVisit', pickBatchPartitionId)

  const getPickTask = async (request: IGetPickTaskRequest): Promise<IPickBatchPartition | null> =>
    await store.dispatch('projects/pick/getPickTask', request)

  const addLoadCarrier = async (
    request: IAddLoadCarrierRequest
  ): Promise<IAddLoadCarrierResponse> =>
    await store.dispatch('projects/pick/addLoadCarrier', request)

  const removeProjects = async () => {
    await store.dispatch('projects/pick/removeProjects')
  }

  const updatePrintedDocuments = async (printed: boolean) => {
    await store.dispatch('projects/pick/updatePrintedDocuments', printed)
  }

  const refreshProject = async () => {
    const response = await getPickTask({
      partitionQueue: selectedQueue.value
        ? {
            identifier: selectedQueue.value?.identifier.toString(),
            type: selectedQueue.value?.type
          }
        : null
    })

    if (response) {
      const project: IOutboundPickProject = {
        selectedPickBatchPartition: response
      }
      await updateProject(project)
    }
  }

  const isComplete = (pickBatchPartition: IPickBatchPartition | null): boolean => {
    if (!pickBatchPartition) {
      return false
    }

    return pickBatchPartition.lineCount === pickBatchPartition.processedLines
  }

  async function onPickCompleted(pickBatchId: ID) {
    await removeProject(pickBatchId)

    await perceptibleNotification.finishedTask(`Pick batch #"${pickBatchId}" completed!`)

    await f7.views.main.router.navigate('/home/', {
      clearPreviousHistory: true
    })
  }

  async function onLoadCarrierFinalized(pickBatchId: string | number) {
    await perceptibleToast.success(
      `Load carrier successfully finalized for pick batch #${pickBatchId}!`
    )

    await f7.views.main.router.navigate(
      `/pick/line/${activeProject.value.selectedPickBatchPartition.id}/`,
      {
        props: {
          pickBatchPartition: activeProject.value.selectedPickBatchPartition
        }
      }
    )
  }

  const verifyBarcode = async (
    verificationCode: string,
    pickBatchPartitionId: ID
  ): Promise<boolean> => {
    return await withAsyncLoading(async (): Promise<boolean> => {
      try {
        const result = await markPicked({
          pickBatchPartitionId,
          verificationCode
        })

        if (result.pickCompleted) {
          await onPickCompleted(pickBatchPartitionId)

          return true
        }

        await onLoadCarrierFinalized(pickBatchPartitionId)

        return true
      } catch (e) {
        await perceptibleToast.error(e.message)
        isLoading.value = false
        throw e
      }
    })
  }

  const getLoadCarrierById = async (request: IGetLoadCarrierByIdRequest): Promise<ILoadCarrier> =>
    await store.dispatch('projects/pick/getLoadCarrierById', request)

  return {
    isActive,
    itemCount,
    items,
    activeProject,
    selectedQueue,
    getPickTask,
    updateProject,
    startPickProcess,
    savePickBatchLineState,
    removeProject,
    addProject,
    markPicked,
    refreshProject,
    isComplete,
    getLocationToVisit,
    updateProcessedLines,
    updateSelectedQueue,
    addLoadCarrier,
    removeProjects,
    verifyBarcode,
    startKardexPick,
    getPickBatchPartitionQueues,
    updatePrintedDocuments,
    getLoadCarrierById,
    createNewPickLoadCarrier
  }
}
