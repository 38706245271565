<template>
  <f7-page :page-content="false">
    <navigation />
    <f7-page-content ptr :ptr-distance="50" ptr-mousewheel @ptr:refresh="onPullToRefresh">
      <pull-to-refresh-preloader />
      <f7-card v-if="queues.length > 1">
        <f7-list>
          <f7-list-input
            :class="{ 'skeleton-text': isLoading }"
            label="Queue"
            type="select"
            :value="selectedQueue?.identifier"
            required
            error-message="Queue can't be empty..."
            @input="onQueueSelect(Number($event.target.value))"
            placeholder="Select a queue..."
          >
            <option :value="queue.identifier" v-for="queue in queues" :key="queue.identifier">
              {{ queue.name }}
            </option>
          </f7-list-input>
        </f7-list>
      </f7-card>

      <f7-block-header
        v-if="pickBatchPartition && pickBatchPartition.pickBatch.description"
        :class="{ 'skeleton-text': isLoading }"
      >
        Detail
      </f7-block-header>
      <f7-card
        v-if="pickBatchPartition && pickBatchPartition.pickBatch.description"
        :class="{ 'skeleton-text': isLoading }"
        @click="notePopupOpened = true"
      >
        <f7-card-content>
          <div style="white-space: pre-wrap">{{ pickBatchPartition.pickBatch.description }}</div>
        </f7-card-content>
      </f7-card>
      <f7-block-title
        v-if="pickBatchPartition"
        class="margin-top"
        :class="{ 'skeleton-text': isLoading }"
      >
        Pick batch #{{ pickBatchPartition.pickBatch.id }}, Pick batch partition #{{
          pickBatchPartition?.id
        }}
      </f7-block-title>
      <f7-card v-else :class="{ 'skeleton-text': isLoading }">
        <f7-card-content>
          <icon-with-text
            :icon="['far', 'laugh-beam']"
            description="There is no pending pick task that has been assigned to you."
            title="Hooray, all done!"
          />
        </f7-card-content>
      </f7-card>
      <f7-card v-if="pickBatchPartition" :class="{ 'skeleton-text': isLoading }">
        <f7-card-content>
          <f7-list media-list>
            <f7-list-item header="Description" v-if="pickBatchPartition?.description">
              <StringLabel :value="pickBatchPartition?.description" />
            </f7-list-item>
            <f7-list-item header="Locations to visit">
              <StringLabel :value="pickBatchPartition?.lineCount" />
            </f7-list-item>
            <f7-list-item header="Progress">
              {{ pickBatchPartition?.processedLines ?? 0 }} /
              {{ pickBatchPartition?.lineCount }}
              <f7-progressbar id="demo-inline-progressbar" :progress="progressBarProgression" />
            </f7-list-item>
            <f7-list-item header="Created at">
              <DateTimeLabel :value="pickBatchPartition?.createdAt" />
            </f7-list-item>
          </f7-list>
        </f7-card-content>
      </f7-card>

      <f7-block-header
        v-if="pickBatchPartition && pickBatchPartition.pickBatch.note"
        :class="{ 'skeleton-text': isLoading }"
      >
        Note
      </f7-block-header>
      <f7-card
        v-if="pickBatchPartition && pickBatchPartition.pickBatch.note"
        :class="{ 'skeleton-text': isLoading }"
        @click="notePopupOpened = true"
      >
        <f7-card-content>
          <string-label
            :value="pickBatchPartition.pickBatch?.note ?? ''"
            html
            :limit="600"
            excerpt="....."
          />
        </f7-card-content>
      </f7-card>

      <f7-block v-if="pickBatchPartition" :class="{ 'skeleton-text': isLoading }">
        <f7-button
          v-if="pickBatchPartition.state === 'created'"
          large
          color="text-secondary"
          fill-md
          @click="onStart(pickBatchPartition)"
          :disabled="isStarted || isLoading || isKardexLoading"
        >
          Start pick
        </f7-button>
        <f7-button
          v-else
          large
          color="text-secondary"
          fill-md
          @click="onContinue(pickBatchPartition)"
          :disabled="isStarted || isLoading || isKardexLoading"
        >
          Continue
        </f7-button>
      </f7-block>
    </f7-page-content>
    <f7-popup
      v-if="pickBatchPartition"
      :opened="notePopupOpened"
      swipe-to-close
      @popup:close="notePopupOpened = false"
      class="pick-batch-note-popup"
    >
      <f7-page>
        <f7-navbar title="Note">
          <f7-nav-right>
            <f7-link popup-close> Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <string-label :value="pickBatchPartition.pickBatch?.note ?? ''" html />
        </f7-block>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<script lang="ts" setup>
import useLoading from '@composables/useLoading'
import useProjectPick from '@composables/useProjectPick'
import { IPickBatchPartition, IPickBatchPartitionQueue } from '@graphql/pick/types'
import { toast } from '@services/toast'
import { f7 } from 'framework7-vue'
import { computed, nextTick, onMounted, ref } from 'vue'
import StringLabel from '@components/label/StringLabel.vue'
import DateTimeLabel from '@components/label/DateTime.vue'
import IconWithText from '@components/IconWithText.vue'
import Navigation from '@components/AppNavigation.vue'
import PullToRefreshPreloader from '@components/PullToRefreshPreloader.vue'
import { confirmYesNo } from '@/functions/dialog'
import { captureException } from '@sentry/vue'
import useTask from '@composables/useTask'
import { IKardexProject } from '@graphql/kardex/types'
import { perceptibleToast } from '@services/perceptibleToast'

const props = withDefaults(
  defineProps<{
    selectedPickBatchPartition?: IPickBatchPartition | null
  }>(),
  {
    selectedPickBatchPartition: null
  }
)

const { withTextPreloaderAndNetworkErrorHandlingAsync, isLoading, withAsyncLoading } = useLoading()
const {
  selectedQueue,
  startPickProcess,
  addProject,
  getPickTask,
  getPickBatchPartitionQueues,
  updateSelectedQueue,
  startKardexPick
} = useProjectPick()
const { executeTaskUntilDone } = useTask()

const notePopupOpened = ref<boolean>(false)
const pickBatchPartition = ref<IPickBatchPartition | null>(props.selectedPickBatchPartition)
const queues = ref<IPickBatchPartitionQueue[]>([])
const isKardexLoading = ref<boolean>(false)
const isStarted = ref<boolean>(false)
const currentKardexProject = ref<IKardexProject | null>(null)

onMounted(async () => {
  await withAsyncLoading(async () => {
    queues.value = await getPickBatchPartitionQueues()

    if (queues.value.length === 1) {
      await updateSelectedQueue(queues.value[0])
    }
  })
})

const onQueueSelect = async (value: number) => {
  const queue = queues.value.find(
    (queue: IPickBatchPartitionQueue) => queue.identifier === value.toString()
  )

  if (queue) {
    await updateSelectedQueue(queue)
    await onPullToRefresh(() => {})
  }
}

const onStart = async (pickBatchPartition: IPickBatchPartition) => {
  if (pickBatchPartition.description === 'Kardex') {
    await onKardexPick(pickBatchPartition)
    return
  }

  await onNormalPick(pickBatchPartition)
}

const onKardexPick = async (pickBatchPartition: IPickBatchPartition) => {
  confirmYesNo({
    title:
      'Are you sure that you want to start picking from the kardex for this pick batch partition?',
    yesButtonCallback: async () => {
      isKardexLoading.value = true

      try {
        await withTextPreloaderAndNetworkErrorHandlingAsync({
          title: `Starting kardex program for #${pickBatchPartition.id}`,
          timeoutText: `This task may take a while depending on the amount of items!`,
          maxRetries: 10,
          autoRetries: 10,
          callback: async () => {
            return await executeTaskUntilDone(
              //@ts-ignore
              async () => {
                currentKardexProject.value = await startKardexPick({
                  pickBatchPartitionId: pickBatchPartition.id
                })

                return currentKardexProject.value.kardexTask
              },
              `Creating kardex orders for pick batch partition "#${pickBatchPartition.id}"`,
              30
            )
          }
        })
      } catch (e) {
        captureException(e)

        toast.error(e.message).open()

        return
      } finally {
        isKardexLoading.value = false
      }

      toast
        .success(`Started pick process for pick batch partition ${pickBatchPartition.id}!`)
        .open()

      await nextTick(() =>
        f7.views.main.router.navigate(`/kardex/${currentKardexProject.value.id}/`)
      )
    }
  })
}

const onNormalPick = async (pickBatchPartition: IPickBatchPartition) => {
  await withAsyncLoading(async () => {
    try {
      await startPickProcess({ pickBatchPartitionId: pickBatchPartition.id })

      await toast
        .success(
          `Started pick process for pick batch partition ${pickBatchPartition.pickBatch.id}!`
        )
        .open()

      isStarted.value = true

      return f7.views.main.router.navigate(`/pick-entry/`)
    } catch (e) {
      captureException(e)

      await perceptibleToast.error(e.message)
    }
  })
}

const onContinue = async (pickBatchPartition: IPickBatchPartition) => {
  if (pickBatchPartition.description === 'Kardex') {
    await onKardexPick(pickBatchPartition)
    return
  }

  await withAsyncLoading(async () => {
    await addProject({
      selectedPickBatchPartition: pickBatchPartition
    })

    isStarted.value = true

    return f7.views.main.router.navigate(`/pick-entry/`, {
      clearPreviousHistory: true
    })
  })
}

const progressBarProgression = computed(() => {
  if (pickBatchPartition.value) {
    return (pickBatchPartition.value.processedLines / pickBatchPartition.value.lineCount) * 100
  }
  return 0
})

const onPullToRefresh = async (done: () => void) => {
  pickBatchPartition.value = await getPickTask({
    partitionQueue: selectedQueue.value
      ? {
          identifier: selectedQueue.value?.identifier.toString(),
          type: selectedQueue.value?.type
        }
      : null
  })

  await done()
}
</script>
