<template>
  <f7-page>
    <app-navigation title="Putaway"></app-navigation>
    <f7-card>
      <f7-card-header>Source</f7-card-header>
      <f7-card-content>
        <div class="source">
          <div class="warehouse-zone">
            <prefix-block
              large
              :prefix="putawaySource.inboundSortLoadCarrier.warehouseZone.prefix"
            />
          </div>
          <div class="description">
            {{ putawaySource.type }}:
            {{ putawaySource.description }}
          </div>
          <quantity-label
            class="quantity-label"
            :quantity="putawaySource.quantity"
          ></quantity-label>
        </div>
      </f7-card-content>
    </f7-card>
    <f7-card>
      <f7-card-header>Destination</f7-card-header>
      <f7-card-content>
        <scan-barcode-animation v-if="!location" title="Scan location" />
        <div class="location" v-if="location">{{ location.name }}</div>
      </f7-card-content>
    </f7-card>
    <f7-block>
      <f7-button large fill color="primary" @click="onConfirm" :disabled="!location || isLoading">
        Confirm
      </f7-button>
    </f7-block>
    <location-suggestion-popup
      :opened="locationSuggesterPopupOpened"
      @close="closeLocationSuggester"
      @on-selected-item="onLocationSelect"
    >
    </location-suggestion-popup>
    <f7-fab position="right-bottom">
      <f7-fab-button @click="openLocationSuggester">
        <f7-icon material="my_location" />
      </f7-fab-button>
    </f7-fab>
  </f7-page>
</template>

<script setup lang="ts">
import { IInboundPutawaySource } from '@graphql/inbound/types'
import AppNavigation from '@components/AppNavigation.vue'
import QuantityLabel from '@components/QuantityLabel.vue'
import ScanBarcodeAnimation from '@components/ScanBarcodeAnimation.vue'
import { eventBus, IEvent } from '@/utilities/scanInput'
import search, { SearchStrategy } from '@services/search/search'
import { TypeName } from '@graphql/search/types'
import { onUnmounted, ref } from 'vue'
import { perceptibleToast } from '@services/perceptibleToast'
import { ILocation } from '@/services/api/graphql/location/types'
import LocationSuggestionPopup from '@components/LocationSuggestionPopup.vue'
import PrefixBlock from '@components/PrefixBlock.vue'
import { soundBoard } from '@services/sound'
import useInbound from '@composables/useInbound'
import useLoading from '@composables/useLoading'
import { f7 } from 'framework7-vue'
import { captureException } from '@sentry/vue'

const { putawayItemToLocation } = useInbound()
const { withAsyncLoading, isLoading } = useLoading()

const props = defineProps<{
  putawaySource: IInboundPutawaySource
}>()

const location = ref<ILocation>()
const locationSuggesterPopupOpened = ref<boolean>(false)

const unsubscribe = eventBus.on('scanInput', async (event: IEvent) => {
  const query = String(event.payload)
  await onLocationScan(query)
})

const openLocationSuggester = () => {
  locationSuggesterPopupOpened.value = true
}

const closeLocationSuggester = () => {
  locationSuggesterPopupOpened.value = false
}

const onLocationScan = async (locationCode: string) => {
  const strategy = search.getStrategy(SearchStrategy.DefaultWithoutRouting)
  const searchResults = await strategy.search(locationCode, true, TypeName.Location, [])
  const locations = searchResults.search

  if (locations.length === 0) {
    await perceptibleToast.error(`No location found with code "${locationCode}"`)
    return
  }

  await soundBoard.playSearchSingleHit()
  onLocationSelect(locations[0] as ILocation)
}

const onLocationSelect = (selectedLocation: ILocation) => {
  location.value = selectedLocation
  closeLocationSuggester()
}

const onConfirm = async () => {
  const selectedLocation = location.value
  if (!selectedLocation) {
    return
  }

  await withAsyncLoading(async () => {
    try {
      const putawayItem = await putawayItemToLocation({
        loadCarrierId: props.putawaySource.inboundSortLoadCarrier.id,
        identifier: props.putawaySource.identifier,
        locationCode: selectedLocation.name
      })

      if (putawayItem.quantity === '0') {
        f7.views.main.router.navigate('/inbound/putaway/', {
          clearPreviousHistory: true
        })
        return
      }

      f7.views.main.router.navigate('/inbound/putaway/item/contents/', {
        clearPreviousHistory: true,
        props: {
          putawayItem: putawayItem
        }
      })
    } catch (e) {
      captureException(e)

      await perceptibleToast.error(e.message)
    }
  })
}

onUnmounted(() => {
  unsubscribe()
})
</script>

<style scoped>
.description,
.quantity-label {
  font-size: 21px;
}

.location {
  display: grid;
  place-items: center;
  width: 100%;
  font-weight: bold;
  font-size: 32px;
}

.source {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.description {
  text-align: center;
}
</style>
