import {
  ColloType,
  colloTypeOptions,
  IAddColliToReceptionRequest,
  IAddItemToInboundSortLoadCarrierRequest,
  ICreateInboundSortLoadCarrierRequest,
  IGetColloByIdQueryRequest,
  IGetInboundSortLoadCarrierByIdRequest,
  IGetInboundSortLoadCarrierItemsByLoadCarrierIdRequest,
  IGetPutawayItemsRequest,
  IGetPutawaySourceRequest,
  IGetReceptionByIdQueryRequest,
  IGetUncompletedInboundSortLoadCarriersByWarehouseZoneRequest,
  IInboundReception,
  IMarkInboundSortLoadCarrierAsSortedRequest,
  IPutawayItemToLocationRequest,
  IPutawayToKardexRequest,
  IRegisterReceptionMutationRequest,
  IUpdateColloMutationRequest
} from '@graphql/inbound/types'
import {
  IColloInput,
  IQuantityInboundColloTypeFormInput,
  IReceptionRegistration
} from '@store/pinia/inbound/types'
import { computed } from 'vue'
import { useInboundStore } from '@store/pinia/inbound/store'
import {
  IMarkLineAsIncompleteResponse,
  IStartKardexPutawayRequest,
  IUpdateReceptionNotesRequest
} from '@graphql/kardex/types'

export default () => {
  const store = useInboundStore()

  const receptionRegistration = computed<IReceptionRegistration>(() => store.receptionRegistration)

  const getColloTypeForHuman = (type: ColloType) => {
    return colloTypeOptions.find((c) => c.key === type.toString()).value
  }

  const addColloTypeQuantity = async (payload: IQuantityInboundColloTypeFormInput) =>
    await store.addColloQuantity(payload)

  const addProcessedCollo = async (payload: IColloInput) => await store.addProcessedCollo(payload)

  const submitReceptionRegistration = async (payload: IRegisterReceptionMutationRequest) =>
    await store.submitReceptionRegistration(payload)

  const addColliToReception = async (request: IAddColliToReceptionRequest) =>
    await store.addColliToReception(request)

  const createReceptionRegistration = async (inboundReception: IInboundReception | null) =>
    await store.createReceptionRegistration(inboundReception)

  const clearReception = () => store.clearReception()

  const findIndexOfExistingQuantityPerColloItem = (item: IQuantityInboundColloTypeFormInput) => {
    return receptionRegistration.value.colli.findIndex((q) => q.type.value === item.type.value)
  }

  const getReceptionById = async (request: IGetReceptionByIdQueryRequest) =>
    await store.getReceptionById(request)
  const getColloById = async (request: IGetColloByIdQueryRequest) =>
    await store.getColloById(request)
  const getReceptions = async () => await store.getReceptions()

  const updateCollo = async (request: IUpdateColloMutationRequest) =>
    await store.updateCollo(request)

  const getUncompletedInboundSortLoadCarriersByZone = async (
    request: IGetUncompletedInboundSortLoadCarriersByWarehouseZoneRequest
  ) => await store.getUncompletedInboundSortLoadCarriersByZone(request)

  const createInboundSortLoadCarrier = async (request: ICreateInboundSortLoadCarrierRequest) =>
    await store.createInboundLoadCarrier(request)

  const addItemToInboundSortLoadCarrier = async (
    request: IAddItemToInboundSortLoadCarrierRequest
  ) => await store.addItemToInboundSortLoadCarrier(request)

  const markInboundSortLoadCarrierAsSorted = async (
    request: IMarkInboundSortLoadCarrierAsSortedRequest
  ) => await store.markInboundSortLoadCarrierAsSorted(request)

  const getColorForState = (state: string) => {
    if (state === 'new') {
      return 'red'
    }

    if (state === 'processing') {
      return 'orange'
    }

    if (state === 'completed') {
      return 'green'
    }

    return ''
  }

  const getInboundSortLoadCarrierById = async (request: IGetInboundSortLoadCarrierByIdRequest) =>
    await store.getInboundSortLoadCarrierById(request)

  const getInboundSortLoadCarrierItemsByLoadCarrierId = async (
    request: IGetInboundSortLoadCarrierItemsByLoadCarrierIdRequest
  ) => await store.getInboundSortLoadCarrierItemsByLoadCarrierId(request)

  const getPutawayItems = async (request: IGetPutawayItemsRequest) =>
    await store.getPutawayItems(request)

  const getPutawaySource = async (request: IGetPutawaySourceRequest) =>
    await store.getPutawaySource(request)

  const putawayItemToLocation = async (request: IPutawayItemToLocationRequest) =>
    await store.putawayItemToLocation(request)

  const putawayToKardex = async (request: IPutawayToKardexRequest) =>
    await store.putawayToKardex(request)

  const startKardexPutaway = async (request: IStartKardexPutawayRequest) =>
    store.startKardexPutaway(request)

  const updateReceptionNotes = async (request: IUpdateReceptionNotesRequest) => {
    if (request.notes) {
      request.notes = request.notes.replace('\n', '<br>')
    }

    return store.updateReceptionNotes(request)
  }

  const markLineAsIncomplete = async (request: IMarkLineAsIncompleteResponse) => {
    return store.markLineAsIncomplete(request)
  }

  return {
    receptionRegistration,
    getColloTypeForHuman,
    addColloTypeQuantity,
    addProcessedCollo,
    submitReceptionRegistration,
    createReceptionRegistration,
    findIndexOfExistingQuantityPerColloItem,
    clearReception,
    getReceptionById,
    getReceptions,
    getColorForState,
    getColloById,
    updateCollo,
    getUncompletedInboundSortLoadCarriersByZone,
    createInboundSortLoadCarrier,
    addItemToInboundSortLoadCarrier,
    markInboundSortLoadCarrierAsSorted,
    getInboundSortLoadCarrierById,
    getInboundSortLoadCarrierItemsByLoadCarrierId,
    getPutawayItems,
    getPutawaySource,
    putawayItemToLocation,
    addColliToReception,
    putawayToKardex,
    startKardexPutaway,
    updateReceptionNotes,
    markLineAsIncomplete
  }
}
