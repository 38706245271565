<template>
  <f7-row no-gap>
    <f7-col width="25">
      <f7-button @click="decrement" :disabled="isDecrementButtonDisabled">
        <f7-icon material="remove_circle_outline" />
      </f7-button>
    </f7-col>
    <f7-col width="50" class="quantity-select">
      <div class="input input-with-value">
        <input
          type="number"
          :autofocus="autoFocusRef"
          class="input input-with-value text-align-center"
          :step="0.0001"
          :value="value ? getStockQuantityAsNumber(article, Number(value)) : value"
          @input="onChange(Number($event.target.value))"
          @click="onClick"
        />
      </div>
      <span>
        {{ article.stockUnit.displayUnit !== 'pc' ? article.stockUnit.displayUnit : '' }}
      </span>
    </f7-col>
    <f7-col width="25">
      <f7-button @click="increment" :disabled="isIncrementButtonDisabled">
        <f7-icon material="add_circle_outline" />
      </f7-button>
    </f7-col>
  </f7-row>
</template>
<script lang="ts" setup>
import { f7 } from 'framework7-vue'
import { computed, ref } from 'vue'
import { IArticle } from '@graphql/article/types'
import useArticle from '@composables/useArticle'

const { getStockQuantityAsNumber, calculateStockQuantity } = useArticle()

const props = defineProps<{
  article: IArticle
  value: number | null
}>()

const emits = defineEmits<{
  (e: 'change', payload: number | null)
}>()

const autofocus = ref(false)

const isDecrementButtonDisabled = computed(() => {
  return props.value <= 0
})
const isIncrementButtonDisabled = computed(() => {
  return props.value >= 10000000
})

const autoFocusRef = async (element: HTMLElement | null) => {
  if (element && autofocus.value) {
    autofocus.value = false
    setTimeout(() => {
      element.focus()
    }, 300)
  }
}

const onChange = (newValue: number | string) => {
  emits('change', calculateStockQuantity(props.article, Number(newValue)))
}

const onClick = (e: any) => {
  if (e.target.value > 0) {
    f7.dialog.confirm('Are you sure you want to change this value?', async () => {
      autofocus.value = true
      await autoFocusRef(e.target)
      await emits('change', null)
    })
  }
}

const increment = () => {
  onChange(
    getStockQuantityAsNumber(
      props.article,
      Number(props.value + props.article.stockUnit.conversionFactor)
    )
  )
}

const decrement = () => {
  if (
    props.value <= 0 ||
    getStockQuantityAsNumber(
      props.article,
      Number(props.value - props.article.stockUnit.conversionFactor)
    ) <= 0
  ) {
    onChange(0)
  } else {
    onChange(
      getStockQuantityAsNumber(
        props.article,
        Number(props.value - props.article.stockUnit.conversionFactor)
      )
    )
  }
}
</script>

<style scoped lang="less">
.quantity-select {
  display: flex;
  align-items: center;
  gap: 10px;
  width: max-content;
  flex-grow: 1;

  .input {
    flex-grow: 1;
  }
}
</style>
