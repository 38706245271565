<template>
  <f7-popup :opened="showRegisterPopup" @popup:close="onCloseRegisterPopup" tablet-fullscreen>
    <f7-page v-if="!registeredLoadCarrier">
      <f7-navbar>
        <f7-nav-title>Register load carrier</f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close>
            <f7-icon material="close" />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-card>
        <f7-card-header>Measurements</f7-card-header>
        <f7-card-content>
          <f7-list media-list>
            <f7-list-item header="Length (CM)">
              <input-number :should-confirm="false" :value="length" @change="length = $event" />
            </f7-list-item>

            <f7-list-item header="Width (CM)">
              <input-number :should-confirm="false" :value="width" @change="width = $event" />
            </f7-list-item>

            <f7-list-item header="Height (CM)">
              <input-number :should-confirm="false" :value="height" @change="height = $event" />
            </f7-list-item>

            <f7-list-item header="Weight (KG)">
              <input-number :should-confirm="false" :value="weight" @change="weight = $event" />
            </f7-list-item>
          </f7-list>
        </f7-card-content>
      </f7-card>

      <f7-block>
        <f7-button color="dimass" text-color="secondary" @click="onConfirm">Confirm</f7-button>
      </f7-block>
    </f7-page>
    <load-carrier-scan-to-print
      v-if="registeredLoadCarrier"
      :verification-popup-opened="verificationPopupOpened"
      @print-documents="onPrintVerificationLabel"
      @confirm-barcode="onVerifyLoadCarrier"
      @verification-popup-closed="verificationPopupOpened = false"
    >
      <template #page-title>Finalize load carrier</template>
      <template #page-content>
        <icon-with-text
          :icon="['far', 'print']"
          title="Scan a nearby label printer"
          description="Print the control label to finalize the picked items for this load carrier. And place the load carrier in the buffer-zone displayed on the printed label."
        />
      </template>
    </load-carrier-scan-to-print>
  </f7-popup>
</template>

<script setup lang="ts">
import { IRegisterableLoadCarrier } from '@graphql/pack/types'
import InputNumber from '@components/form/InputNumber.vue'
import { ref } from 'vue'
import usePack from '@composables/usePack'
import { captureException } from '@sentry/vue'
import { perceptibleToast } from '@services/perceptibleToast'
import LoadCarrierScanToPrint from '@components/LoadCarrierScanToPrint.vue'
import IconWithText from '@components/IconWithText.vue'
import { DocumentKind, PrintContext } from '@graphql/document/types'
import { TypeName } from '@graphql/search/types'
import { soundBoard } from '@services/sound'
import { f7 } from 'framework7-vue'
import useDocument from '@composables/useDocument'

const showRegisterPopup = defineModel<boolean>({ default: false })
const registeredLoadCarrier = ref<IRegisterableLoadCarrier | null>(null)
const verificationPopupOpened = ref<boolean>(false)

const height = ref<number>(0)
const width = ref<number>(0)
const length = ref<number>(0)
const weight = ref<number>(0)

const { printDocumentsFor } = useDocument()
const { registerLoadCarrier } = usePack()

const props = defineProps<{
  selectedLoadCarrier: IRegisterableLoadCarrier
}>()

const emits = defineEmits<{
  (e: 'onClose', payload: void)
  (e: 'registeredLoadCarrier', payload: IRegisterableLoadCarrier)
}>()

const onCloseRegisterPopup = () => {
  emits('onClose')
}

const onConfirm = async () => {
  try {
    registeredLoadCarrier.value = await registerLoadCarrier({
      loadCarrierId: props.selectedLoadCarrier.loadCarrier.id,
      heightInCentimeter: height.value,
      widthInCentimeter: width.value,
      lengthInCentimeter: length.value,
      weightInKilogram: weight.value
    })

    await perceptibleToast.success('Load carrier registered successfully.')

    await onPrintVerificationLabel(null)
  } catch (e) {
    captureException(e)

    await perceptibleToast.error(e.message)
  }
}

const onPrintVerificationLabel = async (printerCode: string | null) => {
  await printDocumentsFor({
    context: new PrintContext(
      registeredLoadCarrier.value?.loadCarrier.id,
      TypeName.LoadCarrier,
      DocumentKind.OUTBOUND_LOAD_CARRIER_COLLI_LABELS,
      printerCode
    )
  })

  await soundBoard.playSuccessSound()
  verificationPopupOpened.value = true
}

const onVerifyLoadCarrier = async (verificationCode: string) => {
  if (
    verificationCode !== registeredLoadCarrier.value?.loadCarrier.id &&
    verificationCode !== registeredLoadCarrier.value?.loadCarrier.verificationCode
  ) {
    await perceptibleToast.error('Invalid verification code')
    return
  }

  emits('registeredLoadCarrier', registeredLoadCarrier.value)

  f7.popup.close('#scan-verification-code-popup')
  verificationPopupOpened.value = false
  registeredLoadCarrier.value = null
}
</script>

<style scoped></style>
